import React, { useMemo, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import InformationList from '@components/InformationList';
import TextHeading from '@components/TextHeading';
import styled, { css } from 'styled-components';
import { colors, fonts, getTypographyStyles } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';
import { Helmet } from 'react-helmet';

const StyledContainer = styled.div`
  padding: 40px 0 120px;

  ${screen.tablet(css`
    padding-top: 46px;
  `)}
`;

const Impact = () => {
  const impactList = useStaticQuery(
    graphql`
      {
        allJsonJson {
          nodes {
            impact {
              list {
                id
                title
                subTitle
                userImportance
                gapBelow
                isFactAndFigure
                sections {
                  id
                  htmlId
                  type
                  value
                  downloadUrl
                }
                list {
                  id
                  title
                  sections {
                    id
                    type
                    value
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  const { list: itemsLists } = impactList.allJsonJson.nodes.find(
    ({ impact }) => !!impact,
  ).impact;

  const renderInformationList = useCallback(
    ({
      id,
      title,
      subTitle,
      imageUrl,
      list,
      sections,
      isFactAndFigure,
      ...rest
    }) => {
      const renderList = (props) => <InformationList {...props} />;

      if (sections || (!sections && !list)) {
        return renderList({
          listCssRules: css`
            max-width: 700px;
            h3 {
              ${getTypographyStyles.heading(true, fonts.fontSecondaryBold)}
            }
            ${screen.xl(css`
              max-width: 700px;
            `)}
          `,
          isImageSmall: true,
          list: [
            {
              title,
              subTitle,
              imageUrl,
              sections,
            },
          ],
          ...rest,
        });
      }

      return renderList({
        listCssRules: css`
          max-width: 1480px;
          padding-right: 64px;
          padding-left: 64px;

          div[class*='StyledItemContainer'] {
            margin-bottom: 0;
          }
          sup {
              font-size: smaller;
              vertical-align: super;
          }

          ${screen.xl(css`
            max-width: available;
          `)}

          ${screen.tablet(css`
            grid-template-columns: repeat(3, 1fr);
          `)}
          ${screen.l(css`
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 32px 32px;
          `)}
          h3 {
            margin-bottom: 24px;
            font-size: 24px;
            line-height: normal;

            a {
              color: ${colors.colorBlack};
              text-decoration: none;
              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }
          }
        `,
        title,
        subTitle,
        list,
        isFactAndFigure,
        ...rest,
      });
    },
    [],
  );

  const lists = useMemo(
    () => itemsLists && itemsLists.map(renderInformationList),
    [itemsLists, renderInformationList],
  );

  const descriptionText = itemsLists
    && itemsLists[0].sections
      .find(({ type }) => type === 'text')
      .value.slice(0, 160);

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <StyledContainer>
        <TextHeading title="Impact" isInvisibleHeading />
        {lists}
      </StyledContainer>

    </>
  );
};

export default Impact;
